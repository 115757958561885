<template>
  <div
    ref="uiScrollWrapper"
    class="relative"
  >
    <div
      v-if="props.withGradient"
      :class="[
        scrollState.left ? 'opacity-100' : 'pointer-events-none opacity-0',
        props.classForArrows,
        props.classForArrowLeft,
        { hidden: scrollToRight },
      ]"
      class="absolute inset-y-0 left-0 z-[2] flex w-8 rotate-180 items-center justify-end bg-gradient-to-l from-white to-transparent align-middle transition-opacity md:w-16"
      @click="scrollContainer('left')"
    >
      <UiIcon
        v-if="props.withIcon"
        :name="iconName"
        class="w-2.5"
        :width="10.12"
        :height="8.65"
      />
    </div>
    <div
      ref="scrollableWrapper"
      class="relative overflow-x-auto overscroll-x-contain hide-scrollbar"
      @scroll.passive="scrollObserver($event)"
      @touchend="stopDragging($event)"
    >
      <!-- @slot for scrollable content -->
      <slot />
    </div>
    <div
      v-if="props.withGradient"
      :class="[
        scrollState.right ? 'opacity-100' : 'pointer-events-none opacity-0',
        props.classForArrows,
        props.classForArrowRight,
      ]"
      class="absolute inset-y-0 right-0 z-[2] flex w-8 items-center justify-end bg-gradient-to-r from-transparent to-white align-middle transition-opacity md:w-16"
      @click="scrollContainer('right')"
    >
      <UiIcon
        v-if="props.withIcon"
        :name="iconName"
        class="w-2.5"
        :width="10.12"
        :height="8.65"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, reactive, ref } from 'vue'
import { useMutationObserver } from '@vueuse/core'
import { RawlplugUiMqKey } from '../../../plugin'
import type { RawlplugUiMq } from '../../../plugin'
import UiIcon from '../../UiIcon/UiIcon.vue'
import type { UiScrollWrapperProps } from './UiScrollWrapper.types'

const props = withDefaults(
  defineProps<UiScrollWrapperProps>(),
  {
    withIcon: true,
    withGradient: true,
    iconName: 'solid-arrow',
    classForArrows: '',
    classForArrowLeft: '',
    classForArrowRight: '',
    scrollToRightIfBcsChange: () => [],
    scrollToRight: false,
    scrollToCenter: false,
    variant: 'free',
  },
)

interface ScrollStateInterface {
  left: boolean
  right: boolean
}

const scrollState: ScrollStateInterface = reactive({
  left: false,
  right: false,
})

const { isDesktop } = (inject(RawlplugUiMqKey) as RawlplugUiMq) || {}
const scrollableWrapper = ref<HTMLElement | null>(null)
const uiScrollWrapper = ref(null)
const scrolledElementOffset = 32 // odległość równa paddingowi - potrzbna by element po scrollu wyświetlał się w całości
function scrollToRightFun() {
  if (scrollableWrapper.value && props.scrollToRight) {
    scrollableWrapper.value.scrollLeft
      = scrollableWrapper?.value?.lastElementChild?.lastElementChild
        ?.offsetLeft ?? 0
  }
}

function scrollToCenterFun() {
  if (scrollableWrapper.value && props.scrollToCenter) {
    const el = scrollableWrapper?.value?.lastElementChild?.querySelector('[data-active=true]')

    const containerWidth = scrollableWrapper.value.offsetWidth
    const distanceLeft = Math.round(el?.offsetLeft) + (Math.round(el?.offsetWidth) / 2)
    const scrollValue = distanceLeft - (containerWidth / 2)

    scrollableWrapper.value.scroll({
      top: 0,
      left: Math.floor(scrollValue),
      behavior: 'smooth',
    })
  }
}

defineExpose({ uiScrollWrapper, scrollToRightFun })
onMounted(() => {
  if (scrollableWrapper.value) {
    setScrollState(scrollableWrapper.value)

    if (props.scrollToRight) {
      scrollToRightFun()
    }

    const { stop: stopMutationObserver } = useMutationObserver(
      scrollableWrapper,
      () => {
        if (scrollableWrapper.value) {
          setScrollState(scrollableWrapper.value)
        }
      },
      {
        childList: true,
        subtree: true,
      },
    )

    onBeforeUnmount(() => {
      stopMutationObserver()
    })
  }
})

onUpdated(() => {
  if (props.scrollToCenter && !isDesktop.value) {
    scrollToCenterFun()
  }
})

function scrollObserver(event: Event) {
  const el = event.target as HTMLElement
  setScrollState(el)
}

function setScrollState(el: HTMLElement) {
  // Math.floor used due to chrome bug with decimals on scrollLeft
  const scrolledDisatance = Math.round(el.scrollLeft)
  scrollState.left = scrolledDisatance > 0
  scrollState.right = el.scrollWidth !== scrolledDisatance + el.clientWidth
}

function freeScroll(direction: 'left' | 'right') {
  const containerWidth = scrollableWrapper.value.offsetWidth
  const distanceLeft = Math.round(scrollableWrapper.value.scrollLeft)
  const scrollValue
    = direction === 'right'
      ? distanceLeft + containerWidth
      : distanceLeft - containerWidth

  return scrollValue
}

function controlledScroll(direction: 'left' | 'right') {
  const elements = Array.from(scrollableWrapper?.value?.lastElementChild?.querySelectorAll('li'))
  const el = elements.find(el => Math.round(el.getBoundingClientRect().left) > scrolledElementOffset)
  const elNext = Math.round(el.getBoundingClientRect().left - scrolledElementOffset)
  const elPrev = Math.round(el.previousSibling.offsetLeft - el.previousSibling.previousSibling.offsetLeft)
  const distanceLeft = Math.round(scrollableWrapper.value.scrollLeft)

  const scrollValue
    = direction === 'right'
      ? distanceLeft + elNext
      : distanceLeft - elPrev

  return scrollValue
}

function scrollContainer(direction?: 'left' | 'right') {
  if (!scrollableWrapper.value) {
    return
  }

  const scrollValue = props.variant === 'controlled' ? controlledScroll(direction) : freeScroll(direction)

  scrollableWrapper.value.scroll({
    top: 0,
    left: Math.round(scrollValue),
    behavior: 'smooth',
  })
}

function stopDragging(event: Event) {
  const el = event.target as HTMLElement
  if (!scrollableWrapper.value || props.variant === 'free') {
    return
  }

  const containerWidth = scrollableWrapper.value.offsetWidth
  const elNext = Math.round(el.getBoundingClientRect().left - scrolledElementOffset)
  const elPrev = Math.floor(containerWidth - el.offsetWidth - elNext)
  const distanceLeft = Math.round(scrollableWrapper.value.scrollLeft)

  const elCenterPos = Math.round((el.getBoundingClientRect().left - scrolledElementOffset) + (el.offsetWidth / 2))

  const scrollValue = elCenterPos < containerWidth / 2
    ? distanceLeft + elNext
    : distanceLeft - elPrev

  scrollableWrapper.value.scroll({
    top: 0,
    left: Math.round(scrollValue),
    behavior: 'smooth',
  })
}
</script>
